.logo {
    display: flex;
    justify-content: left;
    width: 100%;
}

.logo img {
    width: 350px;
    height: auto;
    margin-top: 0px;
}

@media(max-width:768px) {
    .logo {
        position: relative;
        top: 30px;

    }

    .logo img {
        width: 90vw;
        height: auto;
    }

}