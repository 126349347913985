.about {
    width: 100%;
    margin: 0 auto;
}

.about .header {
    display: flex;
    flex-direction: row;
    height: 150px;
    align-items: center;
    width: 95%;
    margin: 0 auto;
}

.about .main {
    width: 100%;
    display: grid;
    gap: 10px;
    background-color: white;

}

.about article {
    background-color: white;
    display: flex;
    width: 70%;
    margin: 0 auto;
}

.about article p {
    font-size: 1.2rem;
    line-height: 30px;
    padding: 50px;
    font-family: var(--brand-font);
    text-align: justify;
    overflow-wrap: normal;
}



@media (max-width:780px) {
    .about .header {
        display: flex;
        flex-direction: column;
        height: 120px;
        align-items: center;
    }


    .about .main {


        margin-top: 45px;
    }

    .about article {
        background-color: white;
        display: flex;
        width: 100%;
        margin: 0px auto;
    }


    .about article p {
        font-size: 0.9rem;
        line-height: 30px;
        padding: 30px;
    }
}