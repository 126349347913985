.card {
    display: flex;
    flex-direction: column;
    border: 2px solid var(--brand-color);
    align-items: center;
    background-color: white;
    margin-bottom: 100px;
}

.card h3 {
    color: rgb(23, 23, 94);
    font-size: 1.5rem;
    padding: 20px 0;
}

.card .cardBody {
    width: 90%;
}


.card img {
    width: 100%;
    height: 250px;
    object-fit: cover;
}

.card p {
    color: black;
    width: 100%;
    padding-top: 30px;
    text-align: justify;
    overflow-wrap: normal;


}