.services {
    width: 95%;
    margin: 0 auto;
}

.services .header {
    display: flex;
    flex-direction: row;
    height: 150px;
    align-items: center;
}

.services .main {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;
}

.services .card {

    padding-bottom: 30px;
}

@media (max-width:780px) {
    .services .header {
        display: flex;
        flex-direction: column;
        height: 120px;
        align-items: center;
    }


    .services .main {

        display: grid;
        grid-template-columns: 1fr;
        margin-top: 30px;
    }

    .services .card {
        margin: 10px 5px 10px 5px;

    }

}