@import './css/reset.css';
@import './css/variables.css';
@import './css/Home.css';
@import './css/About.css';
@import './css/Products.css';
@import './css/Services.css';
@import './css/Card.css';

body {
    background-color: black;
    font-family: var(--brand-font);
}