.footer {
    background-color: var(--brand-color);
    height: auto;
    width: 100%;
    position: relative;

}

.footer address {
    padding: 0 0 2% 2%;
    text-align: center;
}

.footer address span {
    color: white;
    font-size: 0.6rem;
    font-family: var(--brand-font);
}

.footer address a {
    text-decoration: none;
    color: white;
    font-family: var(--brand-font);
    font-size: 0.6rem;
}

@media (min-width:1200px) {

    .footer address span,
    .footer address a {
        font-size: 1rem;
    }

    .footer .location {
        margin: 10px 0;
    }

    .footer .mail {
        padding: 10px 0 0 0;
    }
}