.Navbar {
    width: 100%;

}

.links {
    display: flex;
    height: 50%;
    justify-content: space-around;
}

.links a {
    background-color: transparent;
    border: none;
    padding: 0.3rem 1rem 0.3rem 1rem;
    font-family: var(--brand-font);
    text-decoration: none;
}

.links a:hover {
    cursor: pointer;
    color: var(--brand-color2);
}

.links .activo {
    color: var(--brand-color2);
    border-bottom: 2px solid var(--brand-color);
}

.links .activo:hover:after {

    width: 0;
}

@media (max-width: 768px) {

    .Navbar.active {
        margin: 0 auto;
        width: 100%;
        background-color: rgb(0, 0, 0);
        position: absolute;
        right: 0;
        left: 0;
        z-index: 1;
    }

    .Navbar.active .links {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-left: 10px;
        margin-top: 40px;
        margin-bottom: 20px;
    }

    .Navbar.active .links a {
        display: block;
        font-size: 1rem;
        color: var(--brand-color2);
        padding: 10px;
    }

    .Navbar.active .links .activo {
        color: white;
        border-bottom: 0px;
    }

    .Navbar .links {
        display: none;
    }



    .Navbar .hamburger {
        display: block;
        width: 25px;
        height: 20px;
        position: absolute;
        cursor: pointer;
        top: 10px;
        right: 15px;
    }

    .Navbar .hamburger .line {
        width: 100%;
        height: 2px;
        background-color: var(--brand-color);
        position: absolute;
        left: 0;
        transition: transform 0.5s ease;
    }

    .Navbar .hamburger .line:nth-child(2) {
        top: 50%;
        transform: translateY(-50%);
    }

    .Navbar .hamburger .line:nth-child(3) {
        bottom: 0;
    }

    .Navbar.active .hamburger {
        top: 10px;
        right: 10px;
    }

    .Navbar.active .line:nth-child(1) {
        transform: translateY(10px) rotate(45deg);
        width: 80%;
    }

    .Navbar.active .line:nth-child(2) {
        opacity: 0;
    }

    .Navbar.active .line:nth-child(3) {
        transform: translateY(-8px) rotate(-45deg);
        width: 80%;
    }

    .contacta {
        position: relative;
        top: 50px;
        background-color: var(--brand-color2);
        border: 2px solid var(--brand-color);
        font-family: var(--brand-font);
        margin: 0 auto;
        width: 200px;
        text-align: center;
        padding: 8px;

    }

    .contacta a {
        color: rgb(0, 0, 0);
        text-decoration: none;
    }

    .Navbar.active .contacta {
        display: none;
    }
}

@media (min-width: 1200px) {
    .Navbar {
        width: 100%;
        margin: 0 auto;
        margin-left: -300px;
        display: flex;
        flex-direction: row;
    }

    .links {
        justify-content: space-around;
        width: 100%;
    }

    .links a {
        font-size: 1rem;
        color: white;
        position: relative;
    }

    .links a:after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 0;
        height: 2px;
        background-color: var(--brand-color);
        transition: width 0.3s;
    }

    .links a:hover:after {
        width: 100%;
    }

    .contacta {
        width: 400px;
        display: flex;
        background-color: var(--brand-color2);
        margin: -10px 30px 0 100px;
        border-radius: 2%;
        border: 2px solid var(--brand-color);
        align-items: center;
    }

    .contacta a {
        text-decoration: none;
        padding: 10px;
        font-family: var(--brand-font);
        font-size: 1rem;
        color: black;
        margin: 0 auto;
    }

    .contacta:hover {
        background-color: transparent;
    }

    .contacta:hover a {
        color: white;
        font-weight: bold;
    }
}