.home {
    width: 95%;
    margin: 0 auto;
}

.home .header {
    display: flex;
    flex-direction: row;
    height: 150px;
    align-items: center;
}

.home .hero {
    display: flex;
    flex-direction: row;
    margin-bottom: 50px;
    margin-top: 50px;
    width: 100%;
}

.home .hero img {
    width: 60%;
    height: 30%;
}


.home .hero article {
    margin: 0 auto;
    width: 40%;
    text-align: center;
}


.home .hero article h1 {
    color: var(--brand-color2);
    font-size: 3rem;
    font-family: var(--brand-font);
    animation: titulo 5s infinite;
}

@keyframes titulo {
    0% {
        color: var(--brand-color);
    }

    50% {
        color: var(--brand-color2)
    }

    100% {
        color: var(--brand-color);
    }
}

.home .hero article p {
    color: white;
    font-family: var(--brand-font);
    text-align: justify;
    padding: 40px;
    line-height: 30px;
}

.home .hero ul li {
    color: var(--brand-color2);
    font-family: var(--brand-font);
    font-size: 1.5rem;
    line-height: 40px;
}

@media (max-width:768px) {
    .home .header {
        display: flex;
        flex-direction: column;
        height: 120px;
        align-items: center;
    }

    .home .hero {
        flex-direction: column;
    }

    .home .hero img {
        margin-top: 10px;
        width: 100%;
        height: auto;
        margin: 0 auto;
    }

    .home .hero article {
        width: 100%;
    }

    .home .hero article h1 {
        font-size: 2rem;
        margin-top: 20px;
    }

    .home .hero ul li {
        font-size: 1rem;
    }
}